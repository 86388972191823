import {Component} from 'react';
import {FilteredData, shuffledData} from "../components/user/test/testData/TestData";
import axios from "axios";
import {submitMBTI} from "../components/routes/Api's";
import {
    convertUserResponseToScores,
    resolveTraitFromUserResponse,
    saveTestToLocalStorage, saveUnsentToLocalStorage,
} from "./FunctionalHooks";


class TestHook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            questions: shuffledData,
            email: "",
            isEnabled:false,
            isUnanswered:true,
            userResponse:[],
            trait:"",
            testObj: {
                userTest: FilteredData,
                response: {
                    //Extroverted E vs Introverted I
                    one: "",
                    five: "",
                    nine: "",
                    thirteen: "",
                    seventeen: "",
                    //Sensing S Vs Intuitive N
                    two: "",
                    six: "",
                    ten: "",
                    fourteen: "",
                    eighteen: "",
                    //Thinking T Vs Feeling F
                    three: "",
                    seven: "",
                    eleven: "",
                    fifteen: "",
                    twenty: "",
                    //Judging J Vs Perceptive P
                    four: "",
                    eight: "",
                    twelve: "",
                    sixteen: "",
                    nineteen: "",
                },


            },
        }
    };

    componentDidMount() {
        setInterval(this.timer, 1000)
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        if(userData=== undefined || userData === null){
           return  this.props.history.push("/login")
        }

        //todo if trait exists already, just render trait
        this.setState({email: userData.email});
        this.handleChange = this.handleChange.bind(this);
        this.setCheckedValue = this.setCheckedValue.bind(this);


    };



    timer = () => {
        if (this.state.timeRemaining > 0) {
            this.setState({timeRemaining: this.state.timeRemaining})
        }
    };
setCheckedValue=(checkedValue)=>{
    this.checkedValue = checkedValue;
    this.setState({isEnabled:checkedValue})

}
    resolveAnswers = (e) => {

        const {questions, page, testObj, email} = this.state;
        const mappedQuestions = questions.map((question) => question.question);
        if (page < mappedQuestions.length - 1) {
            this.setState({page: this.state.page + 1});
        }
        this.setState({isUnanswered:true})
        saveTestToLocalStorage(testObj, email);
        if (page === mappedQuestions.length - 1) {
            this.submitTest()
            saveTestToLocalStorage(testObj, email);
        }
    };


    backButton = (event) => {
        if (this.state.page >= 1) {
            const { unanswered, page} = this.state;
            this.setState({page: page - 1, unanswered:false})
        }

    };
    handleChange = (event) => {
        const {response} = this.state.testObj;
        this.setState({
            testObj: {userTest: FilteredData, response: {...response, [event.target.name]: event.target.value}}
        })
        this.setState({isUnanswered: false})

    };

    submitTest = () => {
        const {testObj} = JSON.parse(localStorage.getItem('userTest'));
        convertUserResponseToScores(testObj);
        let trait = resolveTraitFromUserResponse();
        sessionStorage.setItem("trait", JSON.stringify(trait));

       this.postMBTITestToEndPoint(testObj.response, trait)
        this.pushResultsToResultSplashPage(trait)
    };

    postMBTITestToEndPoint = (response, trait) =>{
        const headers = JSON.parse(sessionStorage.getItem("headers"));
        axios.post(submitMBTI,
            {
                responses: response,
                trait: trait
            },
            {
                headers: headers
            }
        ).then(res => {
            if (res.data.success === true) {
                localStorage.removeItem("userTest");
            }
        })
            .catch(err => {
                saveUnsentToLocalStorage(response, trait);
                this.handleFailedTestSubmitError(err)
            });
    }

    submitTestToDB = (response, trait) => {
        const headers = JSON.parse(sessionStorage.getItem("headers"));
        axios.post(submitMBTI,
            {
                responses:response,
                trait:trait
            },
            {
                headers: headers
            }
        ).then(res => {
            if (res.data.success === true) {
                localStorage.removeItem("userTest");
                          }
        })
            .catch(err => {
                saveUnsentToLocalStorage(response, trait);
                this.handleFailedTestSubmitError(err)
            });
    }


    pushResultsToResultSplashPage = (trait) => {
        this.props.history.push({
            pathname: '/test_completed/' + trait

        })
    };



    handleFailedTestSubmitError = (e) => {
        if (e.response === undefined) {
        } else {

            switch (e.response.status) {
                case 401:
                case 403:
                    this.props.history.push('/login');

                    break;

                case 400:

                    break;
                default:
                    break;
            }
        }
    }
    handleFailedTestStopError = (e, email) => {

        if (e.response === undefined) {
            this.props.history.push('/login');

        } else {

            switch (e.response.status) {
                case 401:
                case 403:
                    this.props.history.push('/login');

                    break;

                case 400:

                    break;
                default:
                    break;
            }
        }
    }


}

export default TestHook;
