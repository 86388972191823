import React from 'react';
import '../../../css/test.css'
import TestHook from "../../../hooks/TestHook";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import UserDashBoardHeader from "../UserDashboard/UserDashBoardHeader";


class Test extends TestHook {

    render() {

        const user = JSON.parse(sessionStorage.getItem("userData"));
        const {questions, page, isUnanswered} = this.state;
        const mappedQuestions = questions.map((question) => question.question);
        const mappedOptions = questions[page].options.map(option => option);
        const mappedId = questions.map((id) => id.id);
        const mappedImage = questions.map((image) => image.image);


        function renderImageForQuestion() {
            if (mappedImage[page] === "no image") {
                return (
                    <div>

                    </div>
                )
            } else {
                return (
                    <div className="col-12 col-md-9 order-md-1 float-right image-div">
                        <img src={mappedImage[page]} alt="testImage"/>
                    </div>
                )
            }
        }

        // const buttonStyle = () => {
        //     if (page === 0) {
        //         return {
        //             background: "white",
        //             color: "lightgrey",
        //             border: "2px solid pink"
        //         }
        //     } else {
        //         return {
        //             float: "left"
        //         }
        //     }
        // }
        //

        return (
            <div>
                <div className="mb5s">
                    <UserDashBoardHeader user={user} onOpen={this.openNav}/>
                </div>
                <div className="container-fluid row d-flex justify-content-center ">
                    <div className=" cover-div shadow-3 mt-md-5">
                        <div className="row d-flex justify-content-end">
                            <div className="col-md-6 col-6">
                                <p className="pagination ml-0">
                                    {page + 1}/{questions.length}<span className="spar "><hr className="moves"/>
                                    <div style={{width: 5 * (page + 1)}} className="mover"> </div></span>
                                    {this.isUnanswered = true}
                                </p>
                            </div>
                            <div className="col-md-6 col-6">
                                <p className="float-right timer">
                            {/*        {`Time Started: ${min}:${sec}`}*/}
                                </p>
                            </div>
                        </div>
                        {renderImageForQuestion()}
                        <div className="col-md-7 p-0 question">
                            {/*<p className="question">*/}
                                {mappedQuestions[page]}

                                <RadioGroup className="pl-3" aria-label="gender" name={`${mappedId[page]}`}
                                            value={this.state.testObj.response[mappedId[page]]}
                                            onChange={this.handleChange}>
                                    {
                                        mappedOptions.map((op, index) => {
                                            return (
                                                <div key={index} className="animated">
                                                    <FormControlLabel value={op} control={<Radio/>} label={op}/>
                                                </div>
                                            )
                                        })
                                    }
                                </RadioGroup>

                            <div style={{marginTop: 30, marginBottom: 20, width: 220}}>
                                {/*<button id="nextButton"  style={buttonStyle()} className="mr-2"*/}
                                {/*        onClick={this.backButton}>*/}
                                {/*    <i style={{marginRight: 15, width: 10}} className="fas fa-arrow-left"> </i>*/}
                                {/*    Back*/}
                                {/*</button>*/}



                                {isUnanswered? <div></div> :
                                    <button  id="nextButton" style={page=== mappedQuestions.length -1 ?{
                                    float: "left",
                                    marginTop: -35,
                                    position: "relative",
                                    background: "#FF2E2E",
                                    top: 35,

                                }: {float: "left",
                                    marginTop: -35,
                                    position: "relative",
                                    // background: "#00D689",
                                    top: 35,}
                                } onClick={this.resolveAnswers} >
                                {page === mappedQuestions.length - 1 ? "Submit" : "Next"}
                                    <i style={{marginLeft: 15, width: 10}} className="fas fa-arrow-right"> </i>
                                    </button>
                                }
 {/*{isUnanswered? <div></div> :*/}
 {/*                                   <button  id="nextButton" style={page=== mappedQuestions.length -1 ?{*/}
 {/*                                   float: "right",*/}
 {/*                                   marginTop: -35,*/}
 {/*                                   position: "relative",*/}
 {/*                                   background: "#FF2E2E",*/}
 {/*                                   top: 35,*/}

 {/*                               }: {float: "right",*/}
 {/*                                   marginTop: -35,*/}
 {/*                                   position: "relative",*/}
 {/*                                   // background: "#00D689",*/}
 {/*                                   top: 35,}*/}
 {/*                               } onClick={this.resolveAnswers} >*/}
 {/*                               {page === mappedQuestions.length - 1 ? "Submit" : "Next"}*/}
 {/*                                   <i style={{marginLeft: 15, width: 10}} className="fas fa-arrow-right"> </i>*/}
 {/*                                   </button>*/}
 {/*                               }*/}

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    }


}

export default Test;