import React, {Component} from 'react';
import SplashHeader from "./SplashHeader";
import '../../../css/splash.css'
import {ReactComponent as Thumb} from '../../../images/thumbs-up-black.svg'
import {
    convertServerResponseToScores, fetchQuestionnaireFromDB,
    getTraitInformationForRender,
    log,
    reOrderServerResponse
} from "../../../hooks/FunctionalHooks";
import {getSubmittedMBTI} from "../../routes/Api's";
import axios from "axios";


class ResultSplash extends Component {

    backToDashboard=()=> {

        this.redirectToDashboard(this.props.match.params.userToken);
    }
doNothing=()=>{

}

    componentDidMount() {
        sessionStorage.getItem("serverResponse")? this.doNothing(): fetchQuestionnaireFromDB();
        // sessionStorage.setItem("trait", JSON.stringify(this.props.match.params.trait));

    }


    render() {
        function fetchUserResponse() {
            const headers = JSON.parse(sessionStorage.getItem("headers"));
            axios.get(getSubmittedMBTI,{headers: headers})
                .then(res => {

                    sessionStorage.setItem("serverResponse", JSON.stringify( reOrderServerResponse(res.data.responses)));
                    sessionStorage.setItem("serverResponse", JSON.stringify(res.data.responses));
                    sessionStorage.setItem("trait", JSON.stringify(JSON.parse(sessionStorage.getItem("userData")).trait));
                    convertServerResponseToScores(JSON.parse(sessionStorage.getItem("serverResponse")));
                })
                .catch(e=> {log(e)})

            return undefined;
        }

        function doNothing() {

        }

        return (
            <div className="bac vh-100">
                <SplashHeader/>
                <div className="container  d-flex justify-content-around align-items-center pa3 mt-md-5 ">
                    <div className="box align-self-md-start  col-md-4 br4  border border-danger">
                        <div className=" col-12 col-md-12 d-flex ">
                            <div className=" thumb ">
                                <Thumb/>
                            </div>
                        </div>

                        <div className="text-area">
                            <p className="submit-text"> {getTraitInformationForRender(this.props.match.params.trait).KeyName}
                                <br/>
                                <h6> ({getTraitInformationForRender(this.props.match.params.trait).shortName})</h6>
                               </p>
                            <p className="p-text question">
                                <ul>
                               <li> {getTraitInformationForRender(this.props.match.params.trait).features[0]}</li>
                               <li> {getTraitInformationForRender(this.props.match.params.trait).features[1]}</li>
                               <li> {getTraitInformationForRender(this.props.match.params.trait).features[2]}</li>
                                </ul>
                            </p>

                            {sessionStorage.getItem("userResponse")? doNothing() : fetchUserResponse()}
<p>
                        <a  className="p-text" href={"/results/"} style={{color: "#960C0C"}}>Score analysis </a> <br/>
    <a  target="_blank" rel="noopener noreferrer"  className="p-text" href={getTraitInformationForRender(this.props.match.params.trait).link} style={{color: "#960c0c", alignSelf:"center"} }>Find out more</a>
</p>

                            <a href="https://apply.semicolon.africa/login">
                                <button className="next-button" onClick={this.backToDashboard}>Back to Dashboard</button>
                            </a>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResultSplash;