
export const TEST_SUCCESSFUL = `/test_completed/:trait`;
export const START_TEST = `/start_test`;
export const REDIRECT_FROM_SISTER_APPS = `/redirect/:userToken`;
export const TEST = `/test/:id`;
export const LOGIN = `/login`;
export const TEST_MODAL = `/launch_test`;
export const MORE_RESULT = `/results/`;
export const HOME = `/`;
export const NOTFOUND = `*`;
