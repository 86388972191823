import axios from "axios";
import {ELDER_URL, wakeUpBackendServer} from "../components/routes/Api's";
import {fetchQuestionnaireFromDB, submitAnyPreviousTest} from "./FunctionalHooks";
import Login from "../components/user/Login";


const jwtDecode = require('jwt-decode')


class RedirectFromLoginPages extends Login {
    constructor(props) {
        super(props);
        this.state = {
            userToken: "",
            isEnabled: true,
            isAuth: false,
            user: "",
            headers: {
                ContentType: "application/json",
                Authorization: ""
            },
            email: "",


        }
    }

    componentDidMount() {
        this.wakeUpServer();
        submitAnyPreviousTest()
        this.redirectToDashboard(this.props.match.params.userToken);
    }


    redirectToDashboard = (userToken) => {
        const decodedToken = jwtDecode(userToken)
        const trait = decodedToken.userData.trait;
        const headers = {
            ContentType: "application/json",
            Authorization: `Bearer ${userToken}`,
        }
        this.saveUserDataToSessionStorage(decodedToken, headers, userToken);
        this.redirectToPage(decodedToken.roles.toLowerCase(),  trait);

    }
    saveUserDataToSessionStorage = (decodedToken, header, token) => {
        this.setState({user: decodedToken.userData});
        sessionStorage.setItem("userData", JSON.stringify(decodedToken.userData));
        sessionStorage.setItem("headers", JSON.stringify(header));
        sessionStorage.setItem("token", JSON.stringify(token));
    }

    redirectToPage = (role, trait) => {

        if(trait==="" || trait === null){
            this.pushUserToTestPage();

        }
        else{
            fetchQuestionnaireFromDB();
            this.pushUserToResultPageWith(trait.toUpperCase())
        }
    }


    redirect = (token) => {
        window.location.href = ELDER_URL + '/redirect/' + token;

    }

    wakeUpServer = () => {
        axios.get(wakeUpBackendServer).then(r => "do nothing");
    }


     pushUserToResultPageWith=(trait)=> {
        return this.props.history.push(`/test_completed/${trait}`)

    }

    pushUserToTestPage=()=> {
        return this.props.history.push(`/launch_test`)

    }
}

export default RedirectFromLoginPages;