import React from "react";
// import personalities from '../data/personalityType.json'
import ProgressBar from "../ProgressBar";
import {BreakDown, Content, PercentDisplay, Personality, Type} from './Result.styles'
import UserDashBoardHeader from "../components/user/UserDashboard/UserDashBoardHeader";
import SplashHeader from "../components/user/SplashScreens/SplashHeader";
import {ReactComponent as Thumb} from "../images/thumbs-up-black.svg";
import {getTraitInformationForRender, log} from "../hooks/FunctionalHooks";



const Result = () => {

    if(sessionStorage.getItem("userResponse")=== undefined || sessionStorage.getItem("userResponse")=== null || sessionStorage.getItem("userResponse")===""){
        this.props.history.push("/login")
    }
    const responses = JSON.parse(sessionStorage.getItem("userResponse"));
    const personalityType = JSON.parse(sessionStorage.getItem("trait"))
    const optionsCount = [[0,0], [0,0], [0,0], [0,0]];

    for(let i = 0; i < 5; i++){
        if(responses[i] === 0){
            optionsCount[0][0] +=1;
        }
        if(responses[i]===1){
            optionsCount[0][1] +=1;
        }
    }
    for(let i = 5; i < 10; i++){
        if(responses[i] === 0){
            optionsCount[1][0] +=1;
        }
        if(responses[i]===1){
            optionsCount[1][1] +=1;
        }
    }
    for(let i = 10; i < 15; i++){
        if(responses[i] === 0){
            optionsCount[2][0] +=1;
        }
        if(responses[i]===1){
            optionsCount[2][1] +=1;
        }
    }
    for(let i = 15; i < 20; i++){
        if(responses[i] === 0){
            optionsCount[3][0] +=1;
        }
        if(responses[i]===1){
            optionsCount[3][1] +=1;
        }
    }
    log(optionsCount)
    sessionStorage.setItem("OptionCount", JSON.stringify(optionsCount))

    let semicolonOrange = "#FFA34E"
    let semicolonBrightGreen1 = "#00D689"
    let semicolonHotRed3 = "#FF8686"
    let semicolonPaleYellow1 = "#1D636B";
    let semicolonRed = "#960C0C";
    const personalityMeaning = [["(E)xtroversion", "(I)ntroversion"], ["(S)ensing", "I(N)tuition"], ["(T)hinking", "(F)eeling"], ["(J)udging", "(P)erceiving"]];
    const colors = [
        semicolonOrange,
        semicolonHotRed3,
        semicolonBrightGreen1,
        semicolonPaleYellow1
    ]

        return (
            <div className="bac vh-100">
                <SplashHeader/>
                <div className="container  d-flex justify-content-around align-items-center pa3 mt-md-5 ">
                    <div className="box align-self-md-start  col-md-4 br4  border border-danger">
                        <div className=" col-12 col-md-12 d-flex ">
                            <p className="submit-text"> {getTraitInformationForRender(JSON.parse(sessionStorage.getItem("trait"))).KeyName}
                                <br/>
                                <h6> ({getTraitInformationForRender(JSON.parse(sessionStorage.getItem("trait"))).shortName})</h6>
                            </p>

                        </div>
                        <Content>

                            <BreakDown>
                                {/*<h3>Here is our analysis</h3>*/}
                                {personalityMeaning.map((arr, index) => {
                                    let width1 = optionsCount[index][0] / 5 * 100;
                                    let width2 = optionsCount[index][1] / 5 * 100;
                                    return (
                                        <div key={index} >
                                            <Type className= "p-text question">
                                                <p style={{fontSize: "small"}}>{arr[0]}</p>
                                                <p>{arr[1]}</p>
                                            </Type>
                                            <PercentDisplay>
                                                <p>{width1}%</p>
                                                <ProgressBar width={width1+width2}
                                                             color={`linear-gradient(to right,
                                                  ${width1 > width2? colors[index]: '#E0E0DE'}, 
                                                  ${width1 > width2? colors[index]: '#E0E0DE'} ${width1}%, 
                                                  ${width1 > width2? '#E0E0DE': colors[index]} ${width1}%)`}/>
                                                <p>{width2}%</p>
                                            </PercentDisplay>
                                        </div>
                                    );
                                })}
                            </BreakDown>
                        </Content>
                        <a href={"/test_completed/"+personalityType}>
                            <button className="next-button" >Back to Summary</button>
                        </a>


                    </div>
                </div>
            </div>
        );
}

export default Result;