

const NumericReasoningQuestions = [
    {
        //extrovert vs Introvert
        id: "one",
        question: `I'd rather...`,
        image: "no image",
        options: ["expend energy, enjoy groups", "conserve energy, enjoy one-on-one"],
        answer: `120`
    },
    {
        id: "five",
        question: `I am...`,
        image: "no image",
        options: ["more outgoing, think out loud", "more reserved, think to yourself"],
        answer: `N25`
    },
    {
        id: "nine",
        question: `Which of these best describes you?`,
        image: "no image",
        options: ["seek many tasks, public activities, interaction with others", "seek private, solitary activities with quiet to concentrate"],
        answer: `N25`
    },
   {
        id: "thirteen",
        question: `Which of these best describes you?`,
        image: "no image",
        options: ["external, communicative, express yourself", "internal,reticent, keep to yourself"],
        answer: `N25`
    },
   {
        id: "seventeen",
        question: `I am...`,
        image: "no image",
        options: ["active, initiate", "reflective, deliberate"],
        answer: `N25`
    },

    // # Sensing S Vs Intuitive N
    {
        id: "two",
        question: `Which of these best describes you?`,
        image: "no image",
        options: ["Interpret literally", "look for meaning and possibilities"],
        answer: `2700`
    },
    {
        id: "six",
        question: `I'm...`,
        image: "no image",
        options: ["practical, realistic, experiential", "imaginative, innovative, theoretical"],
        answer: `59`
    },
    {

        id: "ten",
        question: `Which of these best describes you?`,
        image: "no image",
        options: ["standard, usual, conventional", "different, novel, unique"],
        answer: `7`
    },
    {
        id: "fourteen",
        question: `I'd rather...`,
        image: "no image",
        options: ["focus on here-and-now", "look to the future, global perspective, \"big picture\""],
        answer: `12`
    },

    {
        id: "eighteen",
        question: `Which of these best describes you?`,
        image: "no image",
        options: ["facts, things, \"what is\"", "ideas, dreams, \"what could be\", philosophical"],
        answer: `9`
    },

//     # Thinking T Vs Feeling F
    {
        id: "three",
        question: `I am... `,
        image: "no image",
        options: ["logical, thinking, questioning", "empathetic, feeling, accommodating"],
        answer: `7`
    },

    {
        id: "seven",
        question: `I'm...`,
        image: "no image",
        options: ["candid, straight forward, frank", "tactful, kind, encouraging"],
        answer: `2`
    },
    {
        id: "eleven",
        question: `Which of these best describes you?`,
        image: "no image",
        options: ["firm, tend to criticize, hold the line", "gentle, tend to appreciate, conciliate"],
        answer: `42`
    },

    {
        id: "fifteen",
        question: `I am...`,
        image: "no image",
        options: ["tough-minded, just", "tender-hearted, merciful"],
        answer: `140`
    },

    {
        id: "nineteen",
        question: `Which of these best describes you?`,
        image: "no image",
        options: ["matter of fact, issue-oriented", "sensitive, people-oriented, compassionate"],
        answer: `37`
    },

//     # Judging J Vs Perceptive P
    {
        id: "four",
        question: `I'm very...`,
        image: "no image",
        options: ["organized, orderly", "flexible, adaptable"],
        answer: `36`
    },

    {
        id: "eight",
        question: `Which of these best describes you?`,
        image: "no image",
        options: ["plan, schedule", "unplanned, spontaneous"],
        answer: `25`
    },

    {
        id: "twelve",
        question: `Which of these best describes you?`,
        image: "no image",
        options: ["regulated, structured", "easy-going, \"live\" and \"let live\""],
        answer: `13 years`
    },

    {
        id: "sixteen",
        question: `I love (to)`,
        image: "no image",
        options: ["preparation, plan ahead", "go with the flow, adapt as I go"],
        answer: `1/2`
    },

    {
        id: "twenty",
        question: `I love (to)`,
        image: "no image",
        options: ["control, govern", "latitude, freedom"],
        answer: `120`
    },

    ]

//fisher yates shuffle algorithm
function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}


//shuffle all questions using the fisher yates shuffle algorithm
const shuffledNumericQuestion = shuffle(NumericReasoningQuestions);


export const shuffledData = shuffledNumericQuestion;
export const FilteredData = NumericReasoningQuestions;

