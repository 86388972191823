import axios from 'axios'
import {getSubmittedMBTI, submitMBTI} from "../components/routes/Api's";
import {FilteredData} from "../components/user/test/testData/TestData";
import {PersonalityTrait} from "../components/user/test/testData/Trait";

export function sendDataToDatabase(dataBank, data, apiLink) {
    const search = dataBank.includes(data);

    if (!search) axios.post(apiLink, data)
}


export function submitAnyPreviousTest() {
    if (localStorage.getItem("unsentMBTI")) {
       const userTest = JSON.parse(localStorage.getItem("unsentMBTI"));
       const{response, trait} = userTest;
       submitTestToBackendServer(response, trait)
        return this.props.history.push(`test_completed${trait}`)
    }

}

export function convertUserResponseToScores(testObj){ //1
    const array = []
    const questions = FilteredData;
    const localResponse = testObj.response;
    setUserResponse(questions, localResponse, array);

}

function setUserResponse(questions, localResponse, array) {
    for (let i = 0; i < questions.length; i++) {
        let currentQuestion = questions[i];
        let currentResponse = localResponse[currentQuestion.id]
        if (currentResponse === currentQuestion.options[0]) {
            array[i] = 0;
        } else if (currentResponse === currentQuestion.options[1]) {
            array[i] = 1;
        }
    }
    // this.setState({userResponse: array})
    sessionStorage.setItem("userResponse", JSON.stringify(array));
}

export function convertServerResponseToScores(localResponse){ //1
    const array = []
    setUserResponse(FilteredData, localResponse, array);
    // convertServerResponseToTrait(array)
};

export function convertServerResponseToTrait(userResponse){
    let eOrI = 0;
    let sOrN = 0;
    let tOrF = 0;
    let jOrP = 0;
    let trait = "";
    for (let i = 0; i < 5; i++) {
        eOrI += userResponse[i];
    }
    for (let i = 5; i < 10; i++) {
        sOrN += userResponse[i];
    }
    for (let i = 10; i < 15; i++) {
        tOrF += userResponse[i];
    }
    for (let i = 15; i < 20; i++) {
        jOrP += userResponse[i];
    }

    trait += eOrI <= 2 ? "E" : "I";
    trait += sOrN <= 2 ? "S" : "N";
    trait += tOrF <= 2 ? "T" : "F";
    trait += jOrP <= 2 ? "J" : "P";

    sessionStorage.setItem("trait", JSON.stringify(trait));
    return trait
}

export function resolveTraitFromUserResponse() { //2
    const userResponse = JSON.parse(sessionStorage.getItem("userResponse"));
    return convertServerResponseToTrait(userResponse);
}


export function getTraitInformationForRender(trait){
    for(let i = 0; i < 16; i++){
        if(trait === PersonalityTrait[i].shortName){
            return PersonalityTrait[i];
        }
        //todo push somehow to history
    // history.push("/login")

    }
}







export function submitTestToBackendServer(responses, trait) {
    const headers = JSON.parse(sessionStorage.getItem("headers"));

    axios.post(submitMBTI,
        {
            responses: responses,
            trait: trait,
        },
        {
            headers: headers
        }
    ).then(res => {
        if (res.data.success === true) {
            let nothing = localStorage.getItem("userTest")? localStorage.removeItem("userTest"): null;
            nothing = localStorage.getItem("unsentMBTI")? localStorage.removeItem("unsentMBTI"): null;
        }
    })
        .catch(err => {
            saveTestToLocalStorage(responses, trait);
            handleError(err)

        });
}


export function saveTestToLocalStorage( testObj, email) {
    const userTest = {
        testObj,
        email,
    }
    localStorage.setItem("userTest", JSON.stringify(userTest));

}

export function saveUnsentToLocalStorage( response, trait) {
    const userTest = {
        response,
        trait,
    }
    localStorage.setItem("unsentMBTI", JSON.stringify(userTest));

}

//
export function handleError(err) {


    switch (err.status) {
        case 401:
        case 403:
            this.props.history.push("/login")
            break;
        case 400:


            break;
        default:
            break;
    }
}
export function reOrderServerResponse(serverResponse){
    const myResponse = JSON.parse(JSON.stringify(serverResponse))
    const rightOrder = ["one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen", "twenty"]
    let reOrderedResponse ={};
    for(let i = 0; i < rightOrder.length; i++) {
        reOrderedResponse[rightOrder[i]] = myResponse[rightOrder[i]];
    }
    return reOrderedResponse
}

export function log(message){
    // console.log(message)
}


export function fetchQuestionnaireFromDB(){
    const headers = JSON.parse(sessionStorage.getItem("headers"));
    axios.get(getSubmittedMBTI,{headers: headers})
        .then(res => {
            const serverResponse = res.data.responses

            sessionStorage.setItem("serverResponse", JSON.stringify( reOrderServerResponse(serverResponse)));
            sessionStorage.setItem("trait", JSON.stringify(JSON.parse(sessionStorage.getItem("userData")).trait));
            convertServerResponseToScores(JSON.parse(sessionStorage.getItem("serverResponse")));
        })
        .catch(err=>
            {
                this.props.history.push("/login")
            }
        )


}