import React, {useState} from 'react';
import Menu from '@material-ui/core/Menu';
import {ListItemIcon, ListItemText, MenuItem,} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import DropdownIcon from '../../../images/dropdown-icon.svg';
import MyDashboardIcon from "../../../images/MyDashboardIcon.svg"
import LogoutIcon from "../../../images/LogoutIcon.svg";
import ReadInsightIcon from "../../../images/ReadInsightIcon.svg";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";


const notifications = [

    {
        icon: ReadInsightIcon,
        title: "No New Notification",
        text: "No new Notification",
        link: '#'
    },

];


const StyledMenu = withStyles({
    paper: {
        boxShadow: '0 15px 30px rgba(130, 130, 130, 0.15)',
        padding: '25px 0',
        borderRadius: '16px',
    },
})
((props) => (
    <Menu
        // elevation={0}
        // getContentAnchorEl={null}
        // anchorOrigin={{
        //     vertical: 'bottom',
        //     horizontal: 'center',
        // }}
        // transformOrigin={{
        //     vertical: 'top',
        //     horizontal: 'center',
        // }}
        // {...props}
    />
));

const StyledMenuItem = withStyles({
    root: {
        background: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
})(MenuItem);

const UserAvatar = withStyles({
    root: {
        width: 60,
        height: 60,
        background: '#960C0C'
    }
})(Avatar);


export default function SimpleMenu({user, imageLoading}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNotificationClick = (e) => {
        setNotificationAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNotificationClose = () => {
        setNotificationAnchorEl(null);
    }



    const logOut = () => {
        axios.post("logoutUserAPI", "", {headers: JSON.parse(sessionStorage.getItem("headers"))})
            .then();
        sessionStorage.removeItem("userData");
        sessionStorage.removeItem("headers");
        if (sessionStorage.getItem("formData")) {
            sessionStorage.removeItem("formData");
        }
        if (sessionStorage.getItem("admin")) {
            sessionStorage.removeItem("admin");
        }
        if (sessionStorage.getItem("allApplicants")) {
            sessionStorage.removeItem("allApplicants");
        }

        this.props.history.push('/login')
    }

    const getProfilePicture = () => {

        if (!user.hasOwnProperty("profilePictureUrl")) {
            return (
                <UserAvatar>{user.firstName.slice(0, 1).toUpperCase()}</UserAvatar>
            );
        } else {
            return (
                <UserAvatar alt="..." src={user.profilePictureUrl}/>
            )
        }
    }


    return (
        <React.Fragment>
            <div className="user-header d-flex justify-content-between">
                <div className="notification d-flex">
                    <div className="notif-icon d-flex justify-content-center"
                         aria-controls="notification-icon"
                         aria-haspopup="true"
                         variant="contained"
                         color="primary"
                         onClick={handleNotificationClick}>
                        {/*<img src={returnNotifIcon()} alt=""/>*/}
                    </div>
                    <StyledMenu
                        id="notification-icon"
                        anchorEl={notificationAnchorEl}
                        keepMounted
                        open={Boolean(notificationAnchorEl)}
                        onClose={handleNotificationClose}
                    >
                        {notifications.map((notification, index) => {
                            return (
                                <StyledMenuItem key={index}>
                                    <ListItemIcon>
                                        <img src={notification.icon} className="dropdown-icon" alt=""/>
                                    </ListItemIcon>
                                    <ListItemText primary={notification.text} style={{wordWrap: 'break-word'}}/>
                                </StyledMenuItem>
                            )
                        })
                        }
                    </StyledMenu>
                </div>
                <div className="user-corner d-flex justify-content-between"
                     aria-controls="customized-menu"
                     aria-haspopup="true"
                     color="primary"
                     onClick={handleClick}>
                    <div className="user-image" style={{opacity: imageLoading ? .5 : 1}}>
                        {/*{getProfilePicture()}*/}
                    </div>
                    <div className="d-flex align-items-center justify-content-around" style={{width: 60,}}>
                        {/*<span>{user.firstName}</span> <span className="header-dropdown"><img src={DropdownIcon} alt=""/></span>*/}
                    </div>
                </div>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <StyledMenuItem>
                        <a className='w-100 d-flex' href={`/dashboard`}>
                            <ListItemIcon>
                                <img src={MyDashboardIcon} className="dropdown-icon" alt=""/>
                            </ListItemIcon>
                            <ListItemText primary="My Dashboard"/>
                        </a>
                    </StyledMenuItem>
                    <StyledMenuItem>
                        <a className='w-100 d-flex' onClick={logOut} href="/login">
                            <ListItemIcon>
                                <img src={LogoutIcon} className="dropdown-icon" alt=""/>
                            </ListItemIcon>
                            <ListItemText primary="Log Out"/>
                        </a>
                    </StyledMenuItem>
                </StyledMenu>
            </div>
        </React.Fragment>
    );
}
