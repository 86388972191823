const URL = process.env.REACT_APP_BACKEND_URL
export const ELDER_URL = process.env.REACT_APP_ELDERDASHBOARDURL
export const wakeUpBackendServer = `${URL}/`;

export const cloudinaryAPI = process.env.REACT_APP_CLOUDINARYAPI;
//newly updated
export const loginUserAPI = `${URL}/api/user/login`; //login (method post) //changed from auth to user
export const getApplicantByEmail = (applicantEmail) => `${URL}/api/elder/getApplicantByEmail/${applicantEmail}/`;


//newly created
export const getSubmittedMBTI = `${URL}/api/mbti/questionnaire`; //get test (method get)
export const submitMBTI =  `${URL}/api/mbti/questionnaire`; //submit test (method post)
//System Admin APIs


//No more in use
// const URL = `https://lampback.herokuapp.com`; //backend Root directory
// const URL = `https://lampbackdev.herokuapp.com`; //backend Root directory
// const URL = `https://lampbacktest.herokuapp.com`; //backend Root directory  enable before pushing
//
// const URL = `https://lampback-prod.herokuapp.com`; //backend Root directory //enable before building for production
// const URL ='http://localhost:9002';  //local root directory//
// export const cloudinaryAPI = `https://api.cloudinary.com/v1_1/semicolon-africa/image/upload`;

//
