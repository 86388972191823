// import GlobalStyle from './theme/globalStyle';
import {GlobalStyle} from "./GlobalStyle";
import React from 'react';
import './css/fulldiv.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";



import {
    HOME,
    LOGIN, REDIRECT_FROM_SISTER_APPS,
    START_TEST,
    TEST_SUCCESSFUL,
    TEST_MODAL, MORE_RESULT


} from "./components/routes/Routes";

import Test from "./components/user/test/Test";
import Login from "./components/user/Login";
import ResultSplash from "./components/user/SplashScreens/ResultSplash";
import RedirectFromLoginPages from "./hooks/RedirectFromLoginPages";
import TestModal from "./components/user/UserDashboard/TestModal";
import Result from "./Result";


function App() {
    return (
        <React.Fragment>
            {/*<FormSlider />*/}
            <Router>
                <Switch>
                    <Route path={TEST_SUCCESSFUL} exact component={ResultSplash}/>
                    <Route path={LOGIN} exact component={Login}/>
                    <Route path={START_TEST} exact component={Test}/>
                    <Route path={TEST_MODAL} exact component={TestModal}/>
                    <Route path={MORE_RESULT} exact component={Result}/>

                    <Route path={REDIRECT_FROM_SISTER_APPS} exact component={RedirectFromLoginPages}/>

                    <Route path={HOME} exact component={Login}/>
                    <Route path='/elderDashboard' component={() => {
                        window.location.href = 'https://elder.semicolon.africa/dashboard';
                        return null;
                    }}/>





                </Switch>
            </Router>
        </React.Fragment>
    );
}

// const PrivateRoute =({auth, component: Component, ...rest})=>{
//     return(
//         <Route
//             {...rest}
//             render={
//                 props=> auth? <Component:{...props}/>: <Redirect to={{pathname:"/login"}} />
//             }
//         />
//     )
// }

export default App;
