
const url = `https://www.16personalities.com/`

const Trait = [
    {
        shortName: "INTJ",
        KeyName: `THE ARCHITECT`,
        features: ["Imaginative", "Strategic", "Planner"],
        image: `no image`,
        link: `${url}intj-personality`
    },

    {
        shortName: "INTP",
        KeyName: `THE LOGICIAN `,
        image: 'no image',
        features: ["Innovative", "Curious", "Logical"],
        link: `${url}intp-personality`
    },

    {
        shortName: "ENTJ",
        KeyName: `THE COMMANDER `,
        image: `no image`,
        features: ["Bold", "Imaginative", "Strong-Willed"],
        link: `${url}entj-personality`
    },

    {
        shortName: "ENTP",
        KeyName: `THE DEBATER `,
        image: `no image`,
        features: ["Smart", "Curious", "Intellectual"],
        link: `${url}entp-personality`
    },

    {
        shortName: "INFJ",
        KeyName: `THE ADVOCATE `,
        image: `no image`,
        features: ["Quiet", "Mystical", "Idealist"],
        link: `${url}infj-personality`
    },

    {
        shortName: "INFP",
        KeyName: `THE MEDIATOR `,
        image: `no image`,
        features: ["Poetic", "Kind", "Altruistic"],
        link: `${url}infp-personality`
    },

    {
        shortName: "ENFJ",
        KeyName: `THE PROTAGONIST `,
        image: `no image`,
        features: ["Charismatic", "Inspiring", "Natural Leaders"],
        link: `${url}enfj-personality`
    },


    {
        shortName: "ENFP",
        KeyName: `THE CAMPAIGNER `,
        image: `no image`,
        features: ["Enthusiastic", "Creative", "Sociable"],
        link: `${url}enfp-personality`
    },



    {
        shortName: "ISTJ",
        KeyName: `THE LOGISTICIAN `,
        image: `no image`,
        features: ["Practical", "Fact-Minded", "Reliable"],
        link: `${url}istj-personality`
    },



    {
        shortName: "ISFJ",
        KeyName: `THE DEFENDER `,
        image: `no image`,
        features: ["Protective", "Warm", "Caring"],
        link: `${url}isfj-personality`
    },



    {
        shortName: "ESTJ",
        KeyName: `THE EXECUTIVE `,
        image: `no image`,
        features: ["Organized", "Punctual", "Leader"],
        link: `${url}estj-personality`
    },




    {
        shortName: "ESFJ",
        KeyName: `THE CONSUL `,
        image: `no image`,
        features: ["Caring", "Social", "Popular"],
        link: `${url}esfj-personality`
    },




    {
        shortName: "ISTP",
        KeyName: `THE VIRTUOSO `,
        image: `no image`,
        features: ["Bold", "Practical", "Experimental"],
        link: `${url}istp-personality`
    },




    {
        shortName: "ISFP",
        KeyName: `THE ADVENTURER `,
        image: `no image`,
        features: ["Artistic", "Charming", "Explorers"],
        link: `${url}isfp-personality`
    },




    {
        shortName: "ESTP",
        KeyName: `THE ENTREPRENEUR `,
        image: `no image`,
        features: ["Smart", "Energetic", "Perceptive"],
        link: `${url}estp-personality`
    },




    {
        shortName: "ESFP",
        KeyName: `THE ENTERTAINER `,
        image: `no image`,
        features: ["Spontaneous", "Energetic", "Enthusiastic"],
        link: `${url}esfp-personality`
    },

]



export const PersonalityTrait = Trait;

